<template>
  <section>
    <!-- Main Content Wrapper -->
    <!-- Section - 1 -->
    <div class="page-content-lightestblue-main bg-blue-lighter">
      <div class="page-content-lightestblue-main__container heading">
        <h2>{{ t("LBL_REWARDS_WITH_PAYBACK_POINTS") }}</h2>
      </div>
      <div class="page-content-lightestblue-main__container">
        <div class="page-content-lightestblue-main__container-colLeft">
          <div class="content">
            <p class="pb-text">
              Schon ab einem Punktestand von <strong>200 PAYBACK Punkten</strong> können Sie Ihre gesammelten Punkte einlösen.<br />
              Belohnen Sie sich mit attraktiven Prämien aus unserem Prämienshop oder kaufen Sie direkt mit Punkten vor Ort bei unseren Partnern ein.
            </p>
          </div>
        </div>
        <div class="page-content-lightestblue-main__container-colRight">
          <div class="col-sm-12">
            <img
              alt="Einlösen"
              class="pb-teaser__image img-responsive"
              src="https://images.payback.at/media/global/editorial/at/praemie/punte_einloesen_geschenk_710x450px_step2_4000.png"
              data-baseurl="https://images.payback.at/media/global/editorial/at/praemie/"
              data-src-l="punte_einloesen_geschenk_710x450px_step2_4000.png"
              data-src-l-2x="punte_einloesen_geschenk_710x450px_step2_4000_2x.png"
              data-src-m="punte_einloesen_geschenk_710x450px_step2_4001.png"
              data-src-m-2x="punte_einloesen_geschenk_710x450px_step2_4001_2x.png"
              data-src-s="punte_einloesen_geschenk_710x450px_step2_4002.png"
              data-src-s-2x="punte_einloesen_geschenk_710x450px_step2_4002_2x.png"
              data-src-xl="punte_einloesen_geschenk_710x450px_step2_4029.png"
              data-src-xl-2x="punte_einloesen_geschenk_710x450px_step2_4029.png"
              data-src-xs="punte_einloesen_geschenk_710x450px_step2_4003.png"
              data-src-xs-2x="punte_einloesen_geschenk_710x450px_step2_4003_2x.png"
              data-image-responsive=""
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Section - 2 -->
    <div class="page-content-lightestblue-main">
      <div class="page-content-lightestblue-main__container">
        <div class="page-content-lightestblue-main__container-colLeft">
          <div class="pb-teaser__media">
            <div class="pb-teaser__image-container">
              <img
                alt="Einlösen"
                class="pb-teaser__image img-responsive"
                src="https://images.payback.at/media/global/editorial/at/praemie/punte_einloesen_praemien_710x250px_step2_4000.png"
                data-baseurl="https://images.payback.at/media/global/editorial/at/praemie/"
                data-src-l="punte_einloesen_praemien_710x250px_step2_4000.png"
                data-src-l-2x="punte_einloesen_praemien_710x250px_step2_4000_2x.png"
                data-src-m="punte_einloesen_praemien_710x250px_step2_4001.png"
                data-src-m-2x="punte_einloesen_praemien_710x250px_step2_4001_2x.png"
                data-src-s="punte_einloesen_praemien_710x250px_step2_4002.png"
                data-src-s-2x="punte_einloesen_praemien_710x250px_step2_4002_2x.png"
                data-src-xl="punte_einloesen_praemien_710x250px_step2_4029.png"
                data-src-xl-2x="punte_einloesen_praemien_710x250px_step2_4029.png"
                data-src-xs="punte_einloesen_praemien_710x250px_step2_4003.png"
                data-src-xs-2x="punte_einloesen_praemien_710x250px_step2_4003_2x.png"
                data-image-responsive=""
              />
            </div>
          </div>
        </div>
        <div class="page-content-lightestblue-main__container-colRight">
          <div class="pb-teaser__headlines">
            <h2 class="pb-headline pb-headline_h2">Belohnen Sie sich im Prämienshop</h2>
          </div>
          <div class="pb-teaser__content">
            <p class="pb-text">
              Belohnen Sie sich mit tollen Prämien und sparen Sie dabei bis zu 50%. <br />Stöbern Sie in Kategorien nach Ihrer Lieblingsprämie oder finden Sie unter den aktuellen Bestsellern Ihre
              Wunschprämie. <br />Bereits ab 200 gesammelten Punkten können die Punkte in Prämien eingetauscht werden. Sollten die Punkte nicht ausreichen, ist eine Aufzahlung möglich.
            </p>
          </div>
          <div class="pb-teaser__button pt-4">
            <Button :css-class="'red'" :text="t('Prämie aussuchen')" class="pb-teaser__button-btnRed" @click="goToLandingPage()"></Button>
          </div>
        </div>
      </div>
    </div>

    <!-- Section -3 -->
    <div class="page-content-lightestblue-main bg-blue-lighter">
      <div class="page-content-lightestblue-main__container">
        <div class="page-content-lightestblue-main__container-colLeft">
          <div class="pb-teaser pb-teaser_arrangement-hmcb pb-teaser_alignment-left">
            <div class="pb-teaser__headlines">
              <h2 class="pb-headline pb-headline_h2">Punkte einlösen an der Kassa</h2>
            </div>
            <div class="pb-teaser__content">
              <p class="pb-text">Ab 200 gesammelten Punkten können Sie Ihre Punkte auch direkt an der Kassa einlösen. Dabei wird der Einkaufswert der Punkte vom Gesamtbetrag direkt abgezogen.</p>
              <p class="pb-text">Vor Ort können Sie Ihre gesammelten Punkte aktuell z.B. an der Kassa bei dm einlösen.</p>
            </div>
          </div>
        </div>
        <div class="page-content-lightestblue-main__container-colRight">
          <a ui-sref="payback_home" title="Zu allen Prämien">
            <a ui-sref="cash.discount" title="Zur Kassa">
              <img
                alt="Bezahlen"
                class="pb-teaser__image img-responsive"
                src="https://images.payback.at/media/global/editorial/at/payback-general/mit-punkten-bezahlen-kasssa/punkte-vor-ort-einloesen-half-strap-920x300_4000.png"
                data-baseurl="https://images.payback.at/media/global/editorial/at/payback-general/mit-punkten-bezahlen-kasssa/"
                data-src-l="punkte-vor-ort-einloesen-half-strap-920x300_4000.png"
                data-src-l-2x="punkte-vor-ort-einloesen-half-strap-920x300_4000_2x.png"
                data-src-m="punkte-vor-ort-einloesen-half-strap-920x300_4001.png"
                data-src-m-2x="punkte-vor-ort-einloesen-half-strap-920x300_4001_2x.png"
                data-src-s="punkte-vor-ort-einloesen-half-strap-920x300_4002.png"
                data-src-s-2x="punkte-vor-ort-einloesen-half-strap-920x300_4002_2x.png"
                data-src-xl="punkte-vor-ort-einloesen-half-strap-920x300_4029.png"
                data-src-xl-2x="punkte-vor-ort-einloesen-half-strap-920x300_4029.png"
                data-src-xs="punkte-vor-ort-einloesen-half-strap-920x300_4003.png"
                data-src-xs-2x="punkte-vor-ort-einloesen-half-strap-920x300_4003_2x.png"
                data-image-responsive=""
              />
            </a>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import Button from "@/components/Button/Button";
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { useI18n } from "vue-i18n";
  export default {
    name: "Redemption",
    components: {
      Button,
    },
    setup() {
      const { t } = useI18n();
      const router = useRouter();
      const store = useStore();
      const goToLandingPage = async () => {
        store.dispatch("catalog/setFilters", {});
        store.dispatch("catalog/emptyProductsList");
        router.push("/");
      };
      return {
        t,
        goToLandingPage,
      };
    },
  };
</script>
<style lang="postcss" scoped>
  .page-content-lightestblue-main {
    @apply py-8 px-8;
    &__container {
      h2 {
        @media screen and (max-width: 767px) {
          @apply mx-2;
        }
      }
      &.heading {
        @apply text-left flex justify-start mb-2;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      @apply grid grid-cols-4 gap-2 grid-flow-row-dense  text-left;
      &-colLeft {
        @apply col-span-2;
        @media (max-width: 600px) {
          @apply col-span-1;
        }
      }
      &-colRight {
        @apply col-span-2;
        height: 100%;
        @media (max-width: 600px) {
          @apply col-span-1;
        }
      }
      @media (max-width: 800px) {
        @apply grid-cols-1;
      }
    }
    .pb-teaser {
      &__button {
        &-btnRed {
          @apply w-auto;
        }
      }
    }
  }
</style>
